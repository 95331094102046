import React, { useRef } from "react";
import Link from "next/link";
import { IProfile, IRecruiter } from "@/types/formInfo.interface";
import { Avatar } from "primereact/avatar";
import { useUser } from "@/context/AuthContext";
import { AuthServices } from "@/services/auth.services";
import { StyleClass } from "primereact/styleclass";
import { trackClickSettings, trackClickSignOut } from "@/event/mixpanel";

const UserMenuAndAvatar = ({
  userData,
}: {
  userData: IProfile | IRecruiter | undefined;
}) => {
  const { currentUser } = useUser();

  const userMenu = useRef(null);

  return (
    <li className="border-top-1 surface-border lg:border-top-none flex relative">
      <StyleClass
        nodeRef={userMenu}
        selector="@next"
        enterClassName="hidden"
        enterActiveClassName="scalein"
        leaveToClassName="hidden"
        leaveActiveClassName="fadeout"
        hideOnOutsideClick
      >
        <a
          data-id="user-menu"
          ref={userMenu}
          className=" flex  p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
        >
          <Avatar
            image={userData?.avatar as string}
            shape="circle"
            size="normal"
            className="mr-3 lg:mr-0 border-circle"
            icon={!userData?.avatar && "pi pi-user text-primary"}
          />
        </a>
      </StyleClass>
      <div className="border-round surface-overlay p-3 shadow-2 absolute right-0 top-100 z-5 w-15rem origin-top hidden">
        <div className="text-900 font-medium">
          {userData?.firstName + " " + userData?.lastName}
        </div>
        <ul className="list-none mt-4 p-0 flex flex-column gap-2">
          <li>
            {currentUser?.userType === "talent" ? (
              <Link
                onClick={trackClickSettings}
                className="text-700 hover:text-primary no-underline"
                href="/dashboard/talent/settings"
              >
                Settings
              </Link>
            ) : (
              <Link
                className="text-700 hover:text-primary no-underline"
                href="/onboarding/recruiter"
              >
                Edit Profile
              </Link>
            )}
          </li>
          <li
            data-id="logout"
            className="cursor-pointer text-700 hover:text-primary"
            onClick={() => {
              trackClickSignOut();
              AuthServices.logOut();
            }}
          >
            Sign out
          </li>
        </ul>
      </div>
    </li>
  );
};

export default UserMenuAndAvatar;
