import { trackClickMessagingIcon } from "@/event/mixpanel";
import Link from "next/link";
import { Badge } from "primereact/badge";

const MessagesMenu = ({
  messageNotRead,
  iconOnly,
}: {
  messageNotRead: number;
  iconOnly?: boolean;
}) => (
  <li className="inline-flex relative">
    <Link
      onClick={trackClickMessagingIcon}
      href="/messages"
      className="flex p-3 align-items-center hover:text-900 hover:surface-100 text-primary-500 border-round transition-duration-150 transition-colors w-full no-underline"
    >
      <i className="pi pi-comments text-xl lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
        {!!messageNotRead && (
          <Badge severity="danger" size="normal" value={messageNotRead}></Badge>
        )}
      </i>
      {!iconOnly && (
        <span className="block lg:hidden font-medium">Messages</span>
      )}
    </Link>
  </li>
);

export default MessagesMenu;
