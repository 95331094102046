import { useUser } from "@/context/AuthContext";
import { NotificationServices } from "@/services/notification.services";
import { TNotification } from "@/types/notification.interface";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

function useNotifications() {
  const { currentUser } = useUser();
  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<
    TNotification[]
  >([]);

  const { mutateAsync: readNotifications } = useMutation({
    mutationFn: () =>
      NotificationServices.readNotifications(
        currentUser?.uid,
        currentUser?.userType
      ),
    onError: (error: Error) => {
      console.error(
        "Failed to mark notifications as read:",
        JSON.stringify(error)
      );
    },
  });

  useEffect(() => {
    if (!currentUser?.uid || !currentUser?.userType) {
      setNotifications([]); // Reset notifications or handle as needed when user is not defined
      return;
    }

    // Subscribe to notifications for the current user
    const unsubscribe = NotificationServices.subscribeToNotifications(
      currentUser.uid,
      currentUser.userType,
      (newNotifications) => {
        const sortedNotifications = newNotifications?.sort((a, b) => {
          const aTimestamp =
            a?.createdAt?.seconds * 1000 +
            Math.floor(a?.createdAt?.nanoseconds / 1e6);
          const bTimestamp =
            b?.createdAt?.seconds * 1000 +
            Math.floor(b?.createdAt?.nanoseconds / 1e6);
          return bTimestamp - aTimestamp; // Newest to oldest
        });

        setNotifications(sortedNotifications);

        const newUnreadNotifications = newNotifications.filter((n) => !n.read);
        setUnreadNotifications(newUnreadNotifications);
      }
    );

    // Cleanup subscription on component unmount or if currentUser changes
    return () => unsubscribe();
  }, [currentUser?.uid, currentUser?.userType]); // Dependency array includes properties of currentUser

  return { notifications, unreadNotifications, readNotifications };
}

export default useNotifications;
