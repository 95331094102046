// utils/mixpanel.js

import mixpanel from "mixpanel-browser";

import { isStaging } from "@/utils/environment";
let lastTrackedPage = "";

if (isStaging) {
  mixpanel.init(process.env.mixpanelStaging as string, {
    track_pageview: true,
    persistence: "localStorage",
  });
} else {
  mixpanel.init(process.env.mixpanelProduction as string, {
    track_pageview: true,
    persistence: "localStorage",
  });
}

export const trackPageView = (page: string) => {
  if (page === lastTrackedPage) return;

  mixpanel.track("Page View", { page });
  lastTrackedPage = page;
};

/**
 * Identify the user in Mixpanel.
 * @param {string} userId - The unique identifier for the user.
 */
export const identifyUser = (userId: string) => {
  if (userId) {
    mixpanel.identify(userId);
  } else {
    console.warn("Mixpanel: User ID is undefined");
  }
};

/**
 * Track the First Login Date (set once).
 * @param {string} userId - The unique identifier for the user.
 */
export const trackFirstLoginDate = (userId: string) => {
  identifyUser(userId);
  mixpanel.people.set_once({
    First_Login_Date: new Date().toISOString(),
  });
};

/**
 * Set the Verified status to true.
 * @param {string} userId - The unique identifier for the user.
 */
export const setVerified = (userId: string, status: boolean) => {
  identifyUser(userId);
  mixpanel.people.set({
    Verified: status,
  });
};

/**
 * Set the Registered Date (set once).
 * @param {string} userId - The unique identifier for the user.
 */
export const setRegisteredDate = (userId: string, data: string) => {
  identifyUser(userId);
  mixpanel.people.set_once({
    Registered_Date: new Date(data).toISOString(),
  });
};

/**
 * Set the Onboarding Completed Date (set once).
 * @param {string} userId - The unique identifier for the user.
 */
export const setOnboardingCompletedDate = (userId: string) => {
  identifyUser(userId);
  mixpanel.people.set_once({
    Onboarding_Completed_Date: new Date().toISOString(),
  });
};

/**
 * Set the Published Date (set once).
 * @param {string} userId - The unique identifier for the user.
 */
export const setPublishedDate = (userId: string) => {
  identifyUser(userId);
  mixpanel.people.set_once({
    Published_Date: new Date().toISOString(),
  });
};

/**
 * Generic function to track click events.
 * @param {string} eventName - The name of the event.
 */
export const trackClickEvent = (eventName: string) => {
  mixpanel.track(eventName);
};

export const setUserType = (userType: "talent" | "recruiter" | string) => {
  mixpanel.people.set({
    "User Type": userType,
  });
};

/**
 * Track login method.
 * @param {string} method - 'Google', 'GitHub', or 'Email'.
 */
export const trackLoginMethod = (method: "Google" | "GitHub" | "Email") => {
  mixpanel.track("Login", {
    method,
  });
};

/**
 * Track when Terms and Conditions are viewed.
 */
export const trackViewTermsAndConditions = () => {
  mixpanel.track("View Terms and Conditions link in the login page");
};

/**
 * Track when Privacy Policy is viewed.
 */
export const trackViewPrivacyPolicy = () => {
  mixpanel.track("View Privacy Policy link in the login page");
};

/**
 * Track whether the user chooses AI or manual entry.
 * @param {string} choice - 'AI' or 'Manual'.
 */
export const trackUserEntryChoice = (choice: "AI" | "Manual") => {
  mixpanel.track("User Entry Choice for onboarding", {
    choice,
  });
};

/**
 * Track AI flow events.
 * @param {string} action - 'Upload Resume' or 'Copy/Paste Resume'.
 */
export const trackAIFlowAction = (
  action: "Upload Resume" | "Copy/Paste Resume"
) => {
  mixpanel.track("AI Flow Action", {
    action,
  });
};

/**
 * Track manual flow step completion.
 * @param {number} stepNumber - The step number that was completed.
 */
export const trackManualFlowStepCompleted = (stepNumber: number) => {
  mixpanel.track("Manual Flow Step Completed", {
    step: stepNumber,
  });
};

/**
 * Specific functions for each click event.
 */
export const trackClickStartBuildingCareerStory = () => {
  trackClickEvent("Click on Start Building Your Career Story Now");
};

export const trackClickSignIn = () => {
  trackClickEvent("Click on Sign In");
};

export const trackClickSignUp = () => {
  trackClickEvent("Click on Sign Up");
};

export const trackClickGetEarlyInvitation = () => {
  trackClickEvent("Click on Get An Early Invitation");
};

export const trackClickGetStarted = () => {
  trackClickEvent("Click on Get Started");
};

export const trackClickAboutUs = () => {
  trackClickEvent("Click on About Us");
};

export const trackClickPrivacyPolicy = () => {
  trackClickEvent("Click on Privacy Policy");
};

export const trackClickTermsOfService = () => {
  trackClickEvent("Click on Terms of Service");
};

export const trackClickFacebookIcon = () => {
  trackClickEvent("Click on Facebook icon");
};

export const trackClickInstagramIcon = () => {
  trackClickEvent("Click on Instagram icon");
};

//Talent dashboard

export const trackClickMyTargets = () => {
  mixpanel.track("Click on My Targets");
};

export const trackClickBlockedList = () => {
  mixpanel.track("Click on Blocked List");
};

export const trackClickDidYouKnowArrows = () => {
  mixpanel.track("Click on Did You Know Section Arrows");
};

export const trackClickMainPortfolio = () => {
  mixpanel.track("Click on Main Portfolio to View It");
};

export const trackClickPortfolioViewsIcon = () => {
  mixpanel.track("Click on Portfolio Views Icon");
};

export const trackClickViewSharedLinksIcon = () => {
  mixpanel.track("Click on View Shared Links Icon");
};

export const trackClickDeleteModal = () => {
  mixpanel.track("Click on DELETE in Modal - shared profile");
};

export const trackClickEditModal = () => {
  mixpanel.track("Click on EDIT in Modal - shared profile");
};

export const trackClickCopyModal = () => {
  mixpanel.track("Click on COPY in Modal - shared profile");
};

export const trackClickSharePortfolioIcon = () => {
  mixpanel.track("Click on Share Portfolio Icon");
};

export const trackClickNextInStep1 = () => {
  mixpanel.track("Click on NEXT in First Step share portfolio Modal");
};

export const trackClickCopyLinkInStep2 = () => {
  mixpanel.track("Click on Copy Link in Second Step share portfolio Modal");
};

export const trackClickAddAnotherPerson = () => {
  mixpanel.track(
    "Click on Add Another Person in Second Step share portfolio Modal"
  );
};

export const trackClickSendByEmail = () => {
  mixpanel.track("Click on Send by Email in Second Step share portfolio Modal");
};

export const trackClickOtherPortfolio = () => {
  mixpanel.track("Click on Other Portfolio to View It");
};

export const trackClickDeletePortfolioIcon = () => {
  mixpanel.track("Click on DELETE Icon in Portfolio");
};

export const trackConfirmDeletePortfolio = () => {
  mixpanel.track("Confirm Delete portfolio in Modal");
};

export const trackClickCreateNewStory = () => {
  mixpanel.track("Click on Create New Story Button");
};

//
export const trackClickVideoStoryInDropdown = () => {
  mixpanel.track("Click on Video Story in the Dropdown");
};

export const trackClickNextInVideoStep1 = () => {
  mixpanel.track("Video Story - Step 1: Click on NEXT");
};

export const trackClickSelectVideo = () => {
  mixpanel.track("Video Story - Step 2: Click on Select Video");
};

export const trackClickDeleteVideoIcon = () => {
  mixpanel.track("Video Story - Step 2: Click on DELETE Video Icon");
};

export const trackClickNextInVideoStep2 = () => {
  mixpanel.track("Video Story - Step 2: Click on NEXT");
};

export const trackClickEnhanceWithAI = () => {
  mixpanel.track("Video Story - Step 3: Click on Enhance with AI");
};

export const trackClickHardSkillUsed = () => {
  mixpanel.track("Video Story - Step 3: Click on Hard Skill Used");
};

export const trackClickSoftSkillUsed = () => {
  mixpanel.track("Video Story - Step 3: Click on Soft Skill Used");
};

export const trackClickNextInVideoStep3 = () => {
  mixpanel.track("Video Story - Step 3: Click on NEXT");
};

export const trackClickSelectFileVideoStep = () => {
  mixpanel.track("Video Story - Step 4: Click on Select file");
};

export const trackClickSaveAndExitVideo = () => {
  mixpanel.track("Video Story - Step 4: Click on Save and Exit");
};

export const trackClickAddToPortfolioVideo = () => {
  mixpanel.track("Video Story - Step 4: Click on Add to Portfolio");
};

export const trackClickSelectPortfolioVideo = () => {
  mixpanel.track("Video Story - Step 5: Click on Select a Portfolio");
};

export const trackClickAddToPortfolioInStep5Video = () => {
  mixpanel.track("Video Story - Step 5: Click on Add to Portfolio");
};

//

export const trackClickTextStoryInDropdown = () => {
  mixpanel.track("Click on Text Story in the Dropdown");
};

export const trackClickNextInTextStep1 = () => {
  mixpanel.track("Text Story - Step 1: Click on NEXT");
};

export const trackClickEnhanceWithAIText = () => {
  mixpanel.track("Text Story - Step 2: Click on Enhance with AI");
};

export const trackClickHardSkillUsedText = () => {
  mixpanel.track("Text Story - Step 2: Click on Hard Skill Used");
};

export const trackClickSoftSkillUsedText = () => {
  mixpanel.track("Text Story - Step 2: Click on Soft Skill Used");
};

export const trackClickNextText = () => {
  mixpanel.track("Text Story - Step 2: Click on Next");
};

export const trackClickAddToPortfolioText = () => {
  mixpanel.track("Text Story - Step 3: Click on Add to Portfolio");
};

export const trackClickSaveAndExitText = () => {
  mixpanel.track("Text Story - Step 3: Click on Save and Exit");
};

export const trackClickSelectPortfolioText = () => {
  mixpanel.track("Text Story - Step 4: Click on Select a Portfolio");
};

export const trackClickAddToPortfolioInStep4Text = () => {
  mixpanel.track("Text Story - Step 4: Click on Add to Portfolio");
};

export const trackClickSelectFileText = () => {
  mixpanel.track("Text Story - Step 3: Click on Select File");
};

export const trackClickDeleteFileIcon = () => {
  mixpanel.track("Text Story - Step 3: Click on DELETE File Icon");
};

//
export const trackClickMessagingIcon = () => {
  mixpanel.track("Click on Messaging Icon in Header");
};

export const trackClickNotificationIcon = () => {
  mixpanel.track("Click on Notification Icon in Header");
};

export const trackClickStories = () => {
  mixpanel.track("Click on Stories in Header");
};

export const trackClickDashboard = () => {
  mixpanel.track("Click on Dashboard in Header");
};

export const trackClickUserIcon = () => {
  mixpanel.track("Click on User Icon in Header");
};

export const trackClickSignOut = () => {
  mixpanel.track("Click on Sign Out in Header");
};

export const trackClickSettings = () => {
  mixpanel.track("Click on Settings in Header");
};

export const trackClickStartRecording = () => {
  mixpanel.track("Click on Start Recording in Modal");
};

export const trackClickRecordingDeleteIcon = () => {
  mixpanel.track("Click on DELETE Recording Icon in Modal");
};

export const trackClickSave = () => {
  mixpanel.track("Click on Save in settings Modal");
};

export const trackClickCancel = () => {
  mixpanel.track("Click on Cancel in settings Modal");
};

export default mixpanel;
