"use client";

import React, { useState } from "react";
import Link from "next/link";

import Logo from "@/assets/logo/logo.png";

import TermsAndConditionsModal from "@/components/TermsAndConditionsModal";
import PrivacyAndPolicyModal from "@/components/PrivacyAndPolicyModal";
import {
  trackClickAboutUs,
  trackClickPrivacyPolicy,
  trackClickTermsOfService,
} from "@/event/mixpanel";

const Footer = () => {
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  return (
    <div className="surface-section px-4 md:px-6 lg:px-8">
      <div className="surface-section py-3 flex flex-column sm:flex-row align-items-center justify-content-between">
        <div className="flex align-items-start flex-column lg:flex-row font-medium lg:align-items-center">
          <p className="text-primary-300 py-2 px-3 block">
            AlertPass (c) 2024. All rights reserved
          </p>
          <a
            onClick={() => {
              setTermsModal(true);
              trackClickTermsOfService();
            }}
            className="text-primary-300 cursor-pointer block py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900"
          >
            Terms And Conditions
          </a>
          <a
            onClick={() => {
              setPrivacyModal(true);
              trackClickPrivacyPolicy();
            }}
            className="text-primary-300 cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900"
          >
            Privacy Policy
          </a>
          <Link
            onClick={() => {
              trackClickAboutUs();
            }}
            href="/about"
            className="text-primary-300 cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900 no-underline"
          >
            About Us
          </Link>
        </div>
        <Link href={"/"}>
          <div className="mt-3 sm:mt-0">
            <img src={Logo.src} alt="logo" className="w-10rem" />
          </div>
        </Link>
      </div>
      <TermsAndConditionsModal
        onHide={() => setTermsModal(false)}
        visible={termsModal}
      />
      <PrivacyAndPolicyModal
        onHide={() => setPrivacyModal(false)}
        visible={privacyModal}
      />
    </div>
  );
};

export default Footer;
