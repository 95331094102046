import { useEffect } from "react";
import { useRouter } from "next/router";
import { trackPageView } from "../event/mixpanel";

const useTrackPageViewMixpanel = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
    };

    // Track the initial page load
    trackPageView(router.asPath);

    // Subscribe to route changes
    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);
};

export default useTrackPageViewMixpanel;
