import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { trackClickDashboard, trackClickStories } from "@/event/mixpanel";

const TalentMenu = () => {
  const router = useRouter();

  return (
    <>
      <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
        <li>
          <Link
            onClick={trackClickDashboard}
            href="/dashboard/talent/"
            className={`${
              router.asPath == `/dashboard/talent/`
                ? "text-primary border-bottom-2 border-primary-500"
                : ""
            } flex p-3 h-full lg:px-3 lg:py-5 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
          >
            <i className="pi pi-home mr-2"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={trackClickStories}
            href="/stories"
            className={`${
              router.asPath == `/stories/`
                ? "text-primary border-bottom-2 border-primary-500"
                : ""
            } flex p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
          >
            <span>Stories</span>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default TalentMenu;
