// "use client";
import React, { useEffect, useState } from "react";
import { GoogleTagManager } from "@next/third-parties/google";
import { GoogleAnalytics } from "@next/third-parties/google";

import { useRouter } from "next/router";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthContextProvider } from "@/context/AuthContext";
import { ResumeProvider } from "@/context/ResumeContext";

import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";

import mixpanel, {
  setRegisteredDate,
  setUserType,
  setVerified,
} from "@/event/mixpanel";

import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@/styles/theme.css";
import "./globals.css";
import "@/styles/customScrollbar.css";
import { useUserData } from "@/hooks/useUserData";
import { IS_PRODUCTION } from "@/utils/constants";
import { DragDropContextProvider } from "@/components/Shared/Draggable";
import useTrackPageViewMixpanel from "@/hooks/useTrackPageViewMixpanel";
import { User } from "firebase/auth";

const ALLOWD_ROUTES = [
  "/",
  "/auth/login",
  "/auth/register",
  "/auth/verification-email",
  "/auth/forgot-password",
  "/auth/change-password",
  "/auth/verify",
  "/about",
  `/onboarding/talent/wizard/[uid]`,
  `/shared-list/[recruiterUid]/[sharedListId]`,
  `/public-profile/[userId]`,
  `/p/[sharedProfileId]/[linkId]`,
  `/q/[[...slug]]`,
];

interface UserInfo extends User {
  userType: string;
  uid: string;
}

export default function App({ Component }: { Component: React.FC<any> }) {
  useTrackPageViewMixpanel();
  const [user, setUser] = useState<UserInfo>();
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();

  const { userData, completedProfile, loading } = useUserData(
    user?.uid as string,
    user?.userType as string
  );

  useEffect(() => {
    if (!ALLOWD_ROUTES.includes(router.pathname)) {
      if (user && user?.emailVerified) {
        if (user?.userType === "talent") {
          if (userData && !completedProfile) {
            router.push("/onboarding/talent");
          }
        }
        if (user?.userType === "recruiter") {
          if (userData?.status === process.env.recruiterPendingStatus) {
            router.push("/dashboard/recruiter/pending");
          }
          if (
            userData?.status === process.env.recruiterVerifiedStatus ||
            userData?.status === process.env.recruiterOnboardingStatus
          ) {
            router.push("/onboarding/recruiter");
          }

          if (user?.accessLevel === process.env.recruiterNoAccessLevel) {
            router.push("/dashboard/recruiter/no-access");
          }
        }
      } else if (user && !user?.emailVerified) {
        router.push("/auth/verification-email");
      }
    }
  }, [router.pathname, router.asPath, userData, completedProfile, loading]);

  useEffect(() => {
    if (userData) {
      mixpanel?.identify(userData?.userId);

      mixpanel.people.set({
        $name: `${userData?.firstName || ""} ${userData?.lastName || ""}`,
        $email: userData?.email || "",
      });
      if (user) {
        setVerified(userData?.userId, user?.emailVerified);
        setRegisteredDate(
          userData?.userId,
          user?.metadata?.creationTime as string
        );
        setUserType(user?.userType);
      }
    }
  }, [userData]);

  return (
    <>
      <Head>
        <title>portfolii | Build your career, one story at a time!</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider setUser={setUser}>
          <ResumeProvider>
            <DragDropContextProvider>
              <div className="flex flex-column surface-ground">
                <Header />
                <div
                  className="flex flex-column surface-ground "
                  style={{ minHeight: "calc(100vh - 11rem)" }}
                >
                  <div className="flex flex-column">
                    <Component user={user} />
                  </div>
                </div>
                {router.asPath !== "/" && <Footer />}
              </div>
            </DragDropContextProvider>
          </ResumeProvider>
        </AuthContextProvider>
        {IS_PRODUCTION && (
          <>
            <GoogleTagManager gtmId={process.env.gtmId as string} />
            <GoogleAnalytics gaId={process.env.gaTrackingId as string} />
          </>
        )}
      </QueryClientProvider>
    </>
  );
}
