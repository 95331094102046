import React from "react";
import Link from "next/link";
import { IProfile, IRecruiter } from "@/types/formInfo.interface";
import { Avatar } from "primereact/avatar";
import { useUser } from "@/context/AuthContext";
import { AuthServices } from "@/services/auth.services";
import { trackClickSettings, trackClickSignOut } from "@/event/mixpanel";

const UserMenuAndAvatarMobile = ({
  userData,
}: {
  userData: IProfile | IRecruiter | undefined;
}) => {
  const { currentUser } = useUser();

  return (
    <li className="border-top-1 surface-border lg:border-top-none relative flex align-items-center p-3">
      <Avatar
        image={userData?.avatar as string}
        shape="circle"
        size="large"
        className="mr-3 lg:mr-0 border-circle"
        icon={!userData?.avatar && "pi pi-user text-primary"}
      />
      <div>
        <div className="text-900 font-medium mb-1">
          {userData?.firstName + " " + userData?.lastName}
        </div>
        <ul className="list-none p-0 flex flex-column gap-1">
          <li>
            {currentUser?.userType === "talent" ? (
              <Link
                onClick={trackClickSettings}
                className="text-700 hover:text-primary no-underline"
                href="/dashboard/talent/settings"
              >
                Settings
              </Link>
            ) : (
              <Link
                className="text-700 hover:text-primary no-underline"
                href="/onboarding/recruiter"
              >
                Edit Profile
              </Link>
            )}
          </li>
          <li
            className="cursor-pointer text-700 hover:text-primary"
            onClick={() => {
              trackClickSignOut();
              AuthServices.logOut();
            }}
          >
            Sign out
          </li>
        </ul>
      </div>
    </li>
  );
};

export default UserMenuAndAvatarMobile;
