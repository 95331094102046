import NotificationCard from "@/components/Notification/NotificationCard";
import { trackClickNotificationIcon } from "@/event/mixpanel";
import { TNotification } from "@/types/notification.interface";
import { Badge } from "primereact/badge";
import { StyleClass } from "primereact/styleclass";
import { useRef } from "react";

interface Props {
  unreadNotifications: TNotification[];
  readNotifications: () => void;
  iconOnly?: boolean;
}

const NotificationsMenu = ({
  unreadNotifications,
  readNotifications,
  iconOnly,
}: Props) => {
  const btnRef1 = useRef(null);

  return (
    <li className="inline-flex relative" onClick={trackClickNotificationIcon}>
      <StyleClass
        nodeRef={btnRef1}
        selector="@next"
        enterClassName="hidden"
        enterActiveClassName="scalein"
        leaveToClassName="hidden"
        leaveActiveClassName="fadeout"
        hideOnOutsideClick
      >
        <a
          ref={btnRef1}
          className="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors w-full text-primary-500"
        >
          {!!unreadNotifications?.length ? (
            <i
              className="pi pi-bell text-xl lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"
              onClick={() => readNotifications()}
            >
              <Badge severity="danger"></Badge>
            </i>
          ) : (
            <i className="pi pi-bell text-xl lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"></i>
          )}
          {!iconOnly && (
            <span className="block lg:hidden font-medium">Notifications</span>
          )}
        </a>
      </StyleClass>
      <NotificationCard />
    </li>
  );
};
export default NotificationsMenu;
