import { trackClickUserIcon } from "@/event/mixpanel";
import { IProfile, IRecruiter } from "@/types/formInfo.interface";
import UserMenuAndAvatar from "./UserMenuAndAvatar";
import UserMenuAndAvatarMobile from "./UserMenuAndAvatarMobile";

const UserMenu = ({
  userData,
}: {
  userData: IProfile | IRecruiter | undefined;
}) => (
  <>
    <div className="lg:hidden" onClick={trackClickUserIcon}>
      <UserMenuAndAvatarMobile userData={userData} />
    </div>
    <div className="hidden lg:inline-flex" onClick={trackClickUserIcon}>
      <UserMenuAndAvatar userData={userData} />
    </div>
  </>
);

export default UserMenu;
