export function convertTimestampToDate(timestamp: any) {
  if (!timestamp) {
    return;
  }
  const seconds = timestamp?.seconds || timestamp?._seconds || 0;
  const nanoseconds = timestamp?.nanoseconds || timestamp?._nanoseconds || 0;

  const date = seconds
    ? new Date(seconds * 1000 + nanoseconds / 1000000)
    : new Date(timestamp);

  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const day = date.getDate();

  return `${day} ${month} ${year}`;
}

export function convertTimestampToDateWithoutDay(timestamp: any) {
  if (!timestamp) {
    return;
  }
  const seconds = timestamp?.seconds || timestamp?._seconds || 0;
  const nanoseconds = timestamp?.nanoseconds || timestamp?._nanoseconds || 0;

  const date = seconds
    ? new Date(seconds * 1000 + nanoseconds / 1000000)
    : new Date(timestamp);

  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const day = date.getDate();

  return `${month} ${year}`;
}
