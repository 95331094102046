import { Skeleton } from "primereact/skeleton";

const HeaderSkeleton = () => {
  return (
    <div className="flex justify-content-between w-full">
      <div className="flex align-items-center gap-2">
        <Skeleton width="5rem" height="1rem"></Skeleton>
        <Skeleton width="5rem" height="1rem"></Skeleton>
      </div>
      <div className="flex align-items-center gap-4">
        <Skeleton width="2rem" height="2rem"></Skeleton>
        <Skeleton width="2rem" height="2rem"></Skeleton>
        <Skeleton
          width="2rem"
          height="2rem"
          className="border-circle"
        ></Skeleton>
      </div>
    </div>
  );
};

export default HeaderSkeleton;
