import Link from "next/link";

const SignInSignUpLinks = ({
  trackClickSignIn,
  trackClickSignUp,
}: {
  trackClickSignIn: () => void;
  trackClickSignUp: () => void;
}) => (
  <div className="flex">
    <Link
      onClick={trackClickSignIn}
      data-id="login-link"
      href="/auth/login"
      className="text-center w-5rem font-medium no-underline p-2 text-primary border-round-sm border-1 border-primary"
    >
      Sign In
    </Link>
    <Link
      onClick={trackClickSignUp}
      href="/auth/register"
      className="text-center w-5rem font-medium no-underline ml-2 bg-primary p-2 border-round-sm border-1 border-transparent"
    >
      Sign Up
    </Link>
  </div>
);

export default SignInSignUpLinks;
